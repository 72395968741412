<template>
  <v-img
    :src="src"
    :srcset="srcset"
    :height="height"
    gradient="to bottom, rgba(0,0,0,.2), rgba(0,0,0,.4)"
  >
    <v-row v-if="title" align="center" justify="center" class="fill-height px-3">
      <v-col class="text-center" cols="12">
        <h1 class="display-2 font-weight-thin mb-4 white--text" v-text="title"></h1>
        <h4 v-if="subtitle" class="subtitle-1 white--text" v-text="subtitle"></h4>
      </v-col>
    </v-row>
  </v-img>
</template>

<script>
export default {
  name: 'AppBanner',
  props: {
    src: {
      type: [String, Object],
      required: true,
    },
    srcset: {
      type: String,
      required: false,
    },
    height: {
      type: Number,
      required: false,
      default: 250,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    subtitle: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style scoped>

</style>
