<template>
  <div class="divider-wrapper">
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  name: 'AppDivider',
};
</script>

<style lang="scss" scoped>
  .divider-wrapper {
    width: 224px;
    margin: auto;
  }
</style>
