<template>
  <service
    service-name="rewrite"
  >
  </service>
</template>

<script>
import Service from '@/components/Service.vue';

export default {
  name: 'Rewrite',
  metaInfo() {
    return {
      title: this.$t('services.rewrite.title'),
    };
  },
  components: {
    Service,
  },
};
</script>

<style scoped>

</style>
