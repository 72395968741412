<template>
  <v-sheet>
    <app-banner
      :src="require(`@/assets/images/1280/job.jpg`)"
      :srcset="`${require(`@/assets/images/640/job.jpg`)} 640w,
                ${require(`@/assets/images/1280/job.jpg`)} 1280w,
                ${require(`@/assets/images/1920/job.jpg`)} 1920w`"
      :title="$t('publicwriter.job.title')"
      :subtitle="$t('publicwriter.job.subtitle')"
    >
    </app-banner>
    <app-text
      :title="$t('publicwriter.job.why.title')"
      :text="$t('publicwriter.job.why.text')"
    >
    </app-text>
    <app-divider></app-divider>
    <certifications></certifications>
  </v-sheet>
</template>

<script>
import AppDivider from '@/components/AppDivider.vue';
import AppBanner from '@/components/AppBanner.vue';
import AppText from '@/components/AppText.vue';
import Certifications from '@/components/Certifications.vue';

export default {
  name: 'Job',
  components: {
    Certifications,
    AppText,
    AppDivider,
    AppBanner,
  },
  metaInfo() {
    return {
      title: this.$t('publicwriter.job.title'),
    };
  },
};
</script>

<style scoped>

</style>
