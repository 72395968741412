<template>
  <app-container>
    <app-text :title="$t('cookies.title')" :text="$t('cookies.text')"></app-text>
  </app-container>
</template>

<script>
import AppContainer from '@/components/AppContainer.vue';
import AppText from '@/components/AppText.vue';

export default {
  name: 'Cookies',
  components: { AppText, AppContainer },
  metaInfo() {
    return {
      title: this.$t('cookies.title'),
    };
  },
};
</script>

<style scoped>

</style>
