import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@mdi/font/css/materialdesignicons.css';
import VueI18n from '@/plugins/i18n';
import light from '@/themes/light';

Vue.use(Vuetify);

const LRU = require('lru-cache');

const themeCache = new LRU({
  max: 10,
  maxAge: 1000 * 60 * 60, // 1 hour
});

export default new Vuetify({
  theme: {
    themes: { light },
    options: {
      customProperties: true,
    },
  },
  icons: {
    iconfont: 'mdi',
  },
  options: {
    minifyTheme(css) {
      return process.env.NODE_ENV === 'production'
        ? css.replace(/[\r\n|\r|\n]/g, '')
        : css;
    },
    themeCache,
  },
  lang: {
    t: (key, ...params) => VueI18n.t(key, params),
  },
});
