<template>
  <v-sheet :color="color" :tile="tile">
    <v-sheet
      max-width="1264"
      :class="
        contentClasses ?
        `mx-auto ${contentClasses}` :`mx-auto py-6 px-6 ${!dense ? 'py-md-12' : 'py-md-6'}`"
      :color="color"
      :tile="tile"
    >
      <slot></slot>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: 'AppContainer',
  props: {
    color: {
      type: String,
      required: false,
      default: null,
    },
    tile: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    contentClasses: {
      type: String,
      required: false,
      default: null,
    },
  },
};
</script>

<style scoped>

</style>
