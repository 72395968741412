<template>
  <v-footer padless app absolute>
    <v-card flat tile color="primary" class="white--text text-center" width="100%">
      <v-card-text>
        <v-btn class="mx-4" icon :href="$t('contact.data.sns.facebook')" target="_blank">
          <v-icon size="24px" color="white">mdi-facebook</v-icon>
        </v-btn>
        <v-btn class="mx-4" icon :href="$t('contact.data.sns.linkedin')" target="_blank">
          <v-icon size="24px" color="white">mdi-linkedin</v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        <router-link
          class="white--text no-text-decoration px-2 valign-middle"
          v-text="$t('disclaimer.title')"
          :to="{name: 'disclaimer'}"
        >
        </router-link>
        <router-link
          class="white--text no-text-decoration px-2 valign-middle"
          v-text="$t('cookies.title')"
          :to="{name: 'cookies'}"
        >
        </router-link>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        &copy; {{ year }} <strong v-text="$t('app.name')"></strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
