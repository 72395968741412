<template>
  <v-sheet>
    <app-banner
      :src="require(`@/assets/images/1280/testimony.jpg`)"
      :srcset="`${require(`@/assets/images/640/testimony.jpg`)} 640w,
                ${require(`@/assets/images/1280/testimony.jpg`)} 1280w,
                ${require(`@/assets/images/1920/testimony.jpg`)} 1920w`"
      :title="$t('testimony.title')"
      :subtitle="$t('testimony.subtitle')"
    >
    </app-banner>
    <template v-for="(testimony, index) in testimonies">
      <app-container :key="`${testimony.name}-content`" tile>
        <div class="testimony">
          <h2 v-text="testimony.title" class="text-start"></h2>
          <h3
            class="caption text-start"
            v-if="testimony.subtitle" v-text="testimony.subtitle"
          >
          </h3>
          <p class="my-2 text-start">
            <v-icon color="secondary" small>mdi-format-quote-open</v-icon>
            {{ testimony.text }}
            <v-icon color="secondary" small>mdi-format-quote-close</v-icon>
          </p>
          <p class="caption text-start">
            <v-icon small>mdi-minus</v-icon>
            {{ testimony.author }}
          </p>
        </div>
      </app-container>
      <app-divider
        :key="`${testimony.name}-divider`"
        v-if="index < testimonies.length - 1"
      >
      </app-divider>
    </template>
  </v-sheet>
</template>

<script>
import testimonies from '@/data/testimonies';
import AppContainer from '@/components/AppContainer.vue';
import AppDivider from '@/components/AppDivider.vue';
import AppBanner from '@/components/AppBanner.vue';

export default {
  name: 'Testimony',
  components: { AppDivider, AppContainer, AppBanner },
  metaInfo() {
    return {
      title: this.$t('testimony.title'),
    };
  },
  computed: {
    testimonies() {
      const items = [];
      if (testimonies) {
        testimonies.forEach((testimony) => {
          items.push({
            name: testimony.name,
            title: this.$t(`testimony.testimonies.${testimony.name}.title`),
            subtitle: this.$t(`testimony.testimonies.${testimony.name}.subtitle`),
            text: this.$t(`testimony.testimonies.${testimony.name}.text`),
            author: this.$t(`testimony.testimonies.${testimony.name}.author`),
          });
        });
      }
      return items;
    },
  },
};
</script>

<style lang="scss" scoped>
  .testimony {
    border-left: 3px solid var(--v-primary-base);
    padding-left: 12px;
  }
</style>
