<template>
  <app-container tile :dense="dense" :content-classes="contentClasses">
    <v-row justify="center">
      <v-col cols="12" :class="dense ? 'pa-O' : ''">
        <h2 :class="`mb-2 ${titleDecoration ? 'app-title' : ''}`" v-if="title" v-text="title"></h2>
        <slot name="before"></slot>
        <template v-if="text">
          <p
            v-for="paragraph in text.split('\n')"
            :key="paragraph"
            class="text-justify"
            v-text="paragraph"
          >
          </p>
        </template>
        <slot name="after"></slot>
      </v-col>
    </v-row>
  </app-container>
</template>

<script>
import AppContainer from '@/components/AppContainer.vue';

export default {
  name: 'AppText',
  components: { AppContainer },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    text: {
      type: String,
      required: false,
      default: null,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    contentClasses: {
      type: String,
      required: false,
      default: null,
    },
    titleDecoration: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
};
</script>

<style scoped>

</style>
