<template>
  <div>
    <v-app-bar
      color="primary"
      app
      fixed
      clipped-left
    >
      <v-app-bar-nav-icon
        v-if="drawer"
        class="hidden-lg-and-up white--text"
        @click="$emit('navIconClick', $event)"
      ></v-app-bar-nav-icon>
      <router-link
        class="d-flex align-center no-text-decoration"
        :to="{ name: 'home' }"
      >
        <div class="d-flex align-center">
          <v-img :src="require('@/assets/images/logo.png')" width="40" class="mr-3"></v-img>
          <v-toolbar-title class="headline white--text" v-text="appName"></v-toolbar-title>
        </div>
      </router-link>
      <v-spacer></v-spacer>
      <div>
        <template v-for="route in routes">
          <template v-if="route.show && (!isMobileDisplay || route.mobile)">
            <v-btn
              v-if="!route.children"
              :key="route.name"
              :to="{name: route.name}"
              color="white"
              class="ma-1"
              text
              :icon="isXsDisplay"
            >
              <v-icon :left="!isXsDisplay" v-text="route.icon"></v-icon>
              <template v-if="!isXsDisplay">
                {{ $t(route.name + '.title') }}
              </template>
            </v-btn>
            <v-menu
              v-else
              :key="route.name"
              open-on-hover
              close-on-content-click
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  color="white"
                  class="ma-1"
                  text
                  v-on="on"
                >
                  <v-icon left v-text="route.icon"></v-icon>
                  {{ $t(route.name + '.title') }}
                  <v-icon right>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-for="child in route.children">
                  <v-list-item
                    v-if="child.show"
                    :key="child.name"
                    :to="{name: child.name}"
                  >
                    <v-list-item-title
                      v-text="$t(route.name + '.' + child.name + '.title')"
                    >
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-list>
            </v-menu>
          </template>
        </template>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-if="drawer && isMobileDisplay"
      :value="value"
      app
      fixed
      clipped
      @input="$emit('input', $event)"
      width="265"
    >
      <v-list nav dense rounded class="pa-3">
        <template v-for="route in routes">
          <template v-if="route.show">
            <v-list-item
              v-if="!route.children"
              :key="route.name"
              :to="{name: route.name}"
              color="primary"
            >
              <v-list-item-icon>
                <v-icon v-text="route.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content class="py-0 ml-3">
                <v-list-item-title v-text="$t(route.name + '.title')"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-group
              v-else
              :key="route.name"
              :prepend-icon="route.icon"
              no-action
              :value="route.active"
            >
              <template v-slot:activator>
                <v-list-item-content class="py-0 ml-3">
                  <v-list-item-title v-text="$t(route.name + '.title')"></v-list-item-title>
                </v-list-item-content>
              </template>
              <template v-for="child in route.children">
                <v-list-item
                  v-if="child.show"
                  :key="child.name"
                  :to="{name: child.name}"
                >
                  <v-list-item-content class="py-0 ml-3">
                    <v-list-item-title
                      v-text="$t(route.name + '.' + child.name + '.title')"
                    >
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list-group>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { routes } from '@/router';

export default {
  name: 'AppMenu',
  data: () => ({
    route: 0,
  }),
  computed: {
    routes() {
      const to = this.$route;
      const items = routes;
      items.forEach((route) => {
        route.active = false;
      });
      if (to && to.matched.length > 1) {
        const parent = to.matched.filter((m) => !m.parent)[0];
        const currentRoute = items.filter((route) => route.name === parent.name)[0];
        currentRoute.active = true;
      }
      return items;
    },
    isMobileDisplay() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    isXsDisplay() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    drawer: {
      type: Boolean,
      required: false,
      default: false,
    },
    appName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
