<template>
  <app-container tile>
    <v-row justify="center">
      <v-col cols="4" offset="2">
        <v-img
          :src="require('@/assets/images/home/CNED.png')"
          :alt="$t('certifications.cned.alt')"
          height="96"
          contain
        >
        </v-img>
      </v-col>
      <v-col cols="4">
        <v-img
          :src="require('@/assets/images/home/CNV.png')"
          :alt="$t('certifications.cnv.alt')"
          height="96"
          contain
        >
        </v-img>
      </v-col>
      <v-col cols="2">
      </v-col>
    </v-row>
  </app-container>
</template>

<script>
import AppContainer from '@/components/AppContainer.vue';

export default {
  name: 'Certifications',
  components: { AppContainer },
};
</script>

<style scoped>

</style>
