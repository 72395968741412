<template>
    <v-sheet class="pt-8">
      <v-row align="center" justify="center">
        <v-col cols="12">
          <h2 class="text-center display-1 primary--text" v-text="$t('notfound.title')"></h2>
        </v-col>
        <v-col cols="12">
          <p class="text-center" v-text="$t('notfound.text')"></p>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn color="primary" :to="{name: 'home'}">
            <v-icon small left>mdi-home</v-icon>
            {{ $t('notfound.link') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-sheet>
</template>

<script>
export default {
  name: 'NotFound',
  metaInfo() {
    return {
      title: this.$t('notfound.title'),
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
