<template>
    <app-container>
      <app-text
        :title="$t('disclaimer.title')"
        :title-decoration="false"
        dense
        class="text-center"
      >
      </app-text>
      <app-divider></app-divider>
      <v-row>
        <v-col cols="12" md="4">
          <app-text :title="$t('disclaimer.company.title')" dense content-classes="pa-0"></app-text>
          <app-text
            :title="$t('disclaimer.company.contact.title')"
            :text="$t('disclaimer.company.contact.text')"
            :title-decoration="false"
            dense
            content-classes="pa-0"
          >
          </app-text>
          <app-text
            :title="$t('disclaimer.company.info.title')"
            :text="$t('disclaimer.company.info.text')"
            :title-decoration="false"
            dense
            content-classes="pa-0"
          >
          </app-text>
        </v-col>
        <v-col cols="12" md="4">
          <app-text :title="$t('disclaimer.website.title')" dense content-classes="pa-0"></app-text>
          <app-text
            :title="$t('disclaimer.website.realisation.title')"
            :text="$t('disclaimer.website.realisation.text')"
            :title-decoration="false"
            dense
            content-classes="pa-0"
          >
          </app-text>
          <app-text
            :title="$t('disclaimer.website.hosting.title')"
            :text="$t('disclaimer.website.hosting.text')"
            :title-decoration="false"
            dense
            content-classes="pa-0"
          >
          </app-text>
        </v-col>
        <v-col cols="12" md="4">
          <app-text
            :title="$t('disclaimer.others.reproduction.title')"
            :text="$t('disclaimer.others.reproduction.text')"
            :title-decoration="false"
            dense
            content-classes="pa-0"
          >
          </app-text>
          <app-text
            :title="$t('disclaimer.others.gdpr.title')"
            :text="$t('disclaimer.others.gdpr.text')"
            :title-decoration="false"
            dense
            content-classes="pa-0"
          >
          </app-text>
        </v-col>
      </v-row>
    </app-container>
</template>

<script>
import AppContainer from '@/components/AppContainer.vue';
import AppText from '@/components/AppText.vue';
import AppDivider from '@/components/AppDivider.vue';

export default {
  name: 'Disclaimer',
  components: { AppDivider, AppText, AppContainer },
  metaInfo() {
    return {
      title: this.$t('disclaimer.title'),
    };
  },
};
</script>

<style scoped>

</style>
