<template>
  <div class="mb-3">
    <v-img
      :src="require('@/assets/images/1280/home.jpg')"
      :srcset="`${require(`@/assets/images/640/home.jpg`)} 640w,
                ${require(`@/assets/images/1280/home.jpg`)} 1280w,
                ${require(`@/assets/images/1920/home.jpg`)} 1920w`"
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.3)"
      min-height="200"
      max-height="550"
    >
      <v-row align="center" justify="center" class="fill-height px-3">
        <v-col class="text-center" cols="12">
          <h1
            :class="`display-${$vuetify.breakpoint.smAndDown ? '2' :
                               $vuetify.breakpoint.mdAndDown ? '3' : '4'}
                     font-weight-thin mb-4 white--text`"
            v-text="$t('app.name')"
          >
          </h1>
          <h2
            :class="`${$vuetify.breakpoint.smAndDown ? 'caption' : 'subtitle-1'} white--text mb-4`"
            v-text="$t('home.subtitle')"
          >
          </h2>
          <div class="hidden-xs-only">
            <h4
              :class="`${$vuetify.breakpoint.smAndDown ? 'caption' : 'subtitle-1'} white--text`"
              v-text="$t('home.subtitle2')"
            >
            </h4>
            <h4
              :class="`${$vuetify.breakpoint.smAndDown ? 'caption' : 'subtitle-1'} white--text`"
              v-text="$t('home.subtitle3')"
            >
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-img>
    <app-container tile dense>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <app-text
            :title="$t('home.publicwriter.title')"
            :text="$t('home.publicwriter.text1')"
            content-classes="px-0"
          >
          </app-text>
          <i18n path="home.publicwriter.text2" tag="p" class="text-justify">
            <router-link
              class="accent--text no-text-decoration"
              v-text="$t('home.publicwriter.services')"
              :to="{name: 'list'}"
            >
            </router-link>
          </i18n>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          :to="{name: 'contact'}"
          color="primary"
          v-text="$t('home.publicwriter.link')"
        >
        </v-btn>
      </v-row>
    </app-container>
    <app-divider></app-divider>
    <app-container tile dense>
      <v-row justify="center">
        <v-col cols="12" md="4">
          <h2 class="mb-2 app-title" v-text="$t('home.services.redaction.title')"></h2>
          <p class="text-justify" v-text="$t('home.services.redaction.text')"></p>
          <p class="caption" v-text="$t('home.services.redaction.note')"></p>
        </v-col>
        <v-col cols="12" md="4">
          <h2 class="mb-2 app-title" v-text="$t('home.services.rewrite.title')"></h2>
          <p class="text-justify" v-text="$t('home.services.rewrite.text')"></p>
        </v-col>
        <v-col cols="12" md="4">
          <h2 class="mb-2 app-title" v-text="$t('home.services.correction.title')"></h2>
          <p class="text-justify" v-text="$t('home.services.correction.text')"></p>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          :to="{name: 'list'}"
          color="primary"
          v-text="$t('home.services.link')"
        >
        </v-btn>
      </v-row>
    </app-container>
    <app-divider></app-divider>
    <app-container tile dense>
      <v-row justify="center">
        <v-col cols="12">
          <h2 class="mb-2 app-title" v-text="$t('home.workshop.title')"></h2>
          <p class="text-justify" v-text="$t('home.workshop.text')"></p>
        </v-col>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-btn
          text
          :small="$vuetify.breakpoint.smAndDown"
          :to="{name: 'workshop'}"
          color="primary"
          v-text="$t('home.workshop.link')"
        >
        </v-btn>
      </v-row>
    </app-container>
    <app-divider></app-divider>
    <app-container tile dense>
      <v-row justify="center">
        <v-col cols="12" md="6">
          <app-text
            :title="$t('home.pro.title')"
            :text="$t('home.pro.text')"
            content-classes="px-0"
          >
          </app-text>
          <v-row v-if="$vuetify.breakpoint.smAndDown">
            <v-spacer></v-spacer>
            <v-btn
              text
              :small="$vuetify.breakpoint.smAndDown"
              :to="{name: 'pro'}"
              color="primary"
              v-text="$t('home.pro.link')"
            >
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <app-text
            :title="$t('home.associations.title')"
            :text="$t('home.associations.text')"
            content-classes="px-0"
          >
          </app-text>
          <v-row v-if="$vuetify.breakpoint.smAndDown">
            <v-spacer></v-spacer>
            <v-btn
              text
              :small="$vuetify.breakpoint.smAndDown"
              :to="{name: 'associations'}"
              color="primary"
              v-text="$t('home.associations.link')"
            >
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-if="!$vuetify.breakpoint.smAndDown">
        <v-col cols="12" md="6">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              text
              :small="$vuetify.breakpoint.smAndDown"
              :to="{name: 'pro'}"
              color="primary"
              v-text="$t('home.pro.link')"
            >
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              text
              :small="$vuetify.breakpoint.smAndDown"
              :to="{name: 'associations'}"
              color="primary"
              v-text="$t('home.associations.link')"
            >
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </app-container>
    <app-divider></app-divider>
    <app-container tile dense>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <div class="mb-2 app-title" >
            <h2 v-text="$t('home.me.title')"></h2>
            <h3 class="caption" v-text="$t('home.me.subtitle')"></h3>
          </div>
          <p class="text-justify" v-text="$t('home.me.text')"></p>
          <v-row>
            <v-spacer></v-spacer>
            <v-btn
              text
              :small="$vuetify.breakpoint.smAndDown"
              :to="{name: 'me'}"
              color="primary"
              v-text="$t('home.me.link')"
            >
            </v-btn>
          </v-row>
        </v-col>
        <v-col cols="12" md="4" class="d-flex align-center justify-center">
          <v-img
            class="v-avatar flex-grow-0"
            height="128"
            width="128"
            :src="require('@/assets/images/home/profile.jpg')"
          >
          </v-img>
        </v-col>
      </v-row>
    </app-container>
    <app-divider></app-divider>
    <certifications></certifications>
  </div>
</template>

<script>
import AppContainer from '@/components/AppContainer.vue';
import AppDivider from '@/components/AppDivider.vue';
import Certifications from '@/components/Certifications.vue';
import AppText from '@/components/AppText.vue';

export default {
  name: 'Home',
  metaInfo() {
    return {
      title: this.$t('app.titles.default'),
    };
  },
  components: {
    AppText,
    Certifications,
    AppDivider,
    AppContainer,
  },
};
</script>

<style lang="scss" scoped>

</style>
