<template>
    <v-sheet>
      <app-banner
        :src="require(`@/assets/images/1280/pricing.jpg`)"
        :srcset="`${require(`@/assets/images/640/pricing.jpg`)} 640w,
              ${require(`@/assets/images/1280/pricing.jpg`)} 1280w,
              ${require(`@/assets/images/1920/pricing.jpg`)} 1920w`"
        :title="$t('pricing.title')"
        :subtitle="$t('pricing.subtitle')"
      >
      </app-banner>
      <app-text :text="$t('pricing.text')" dense></app-text>
      <app-divider></app-divider>
      <app-container>
        <v-expansion-panels v-model="panel" hover>
          <v-expansion-panel
            v-for="item in items"
            :key="item.title"
          >
            <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
            <v-expansion-panel-content class="pt-3">
              <v-list three-line rounded class="pa-0">
                <v-list-item-group v-model="item.selected">
                  <v-list-item
                    two-line
                    v-for="(subItem, index) in item.subItems"
                    :key="index"
                    color="primary"
                  >
                    <v-list-item-content class="px-2">
                      <v-list-item-title class="primary--text"
                        v-text="subItem.title"
                      >
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-text="subItem.price"
                      >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item :color="null">
                  <v-list-item-content class="d-flex flex-sm-row">
                    <v-btn
                      :to="{name: item.name}"
                      color="primary"
                      class="mr-2 mb-2"
                      small
                    >
                      <v-icon left small>mdi-pencil</v-icon>
                      {{ $t('services.links.service') }}
                    </v-btn>
                    <v-btn
                      :to="{name: 'contact'}"
                      color="primary"
                      class="mr-2 mb-2"
                      small
                    >
                      <v-icon left small>mdi-email</v-icon>
                      {{ $t('services.links.contact') }}
                    </v-btn>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </app-container>
    </v-sheet>
</template>

<script>
import services from '@/data/services';
import AppContainer from '@/components/AppContainer.vue';
import AppText from '@/components/AppText.vue';
import AppDivider from '@/components/AppDivider.vue';
import AppBanner from '@/components/AppBanner.vue';

export default {
  name: 'Pricing',
  components: {
    AppDivider,
    AppText,
    AppContainer,
    AppBanner,
  },
  metaInfo() {
    return {
      title: this.$t('pricing.title'),
    };
  },
  data: () => ({
    panel: null,
    serviceItem: null,
  }),
  computed: {
    service() {
      return this.$route.query.service;
    },
    item() {
      return this.$route.query.item;
    },
    items() {
      const items = [];
      if (services) {
        services.forEach((service) => {
          if (service && service.items) {
            const subItems = [];
            service.items.forEach((item) => {
              subItems.push({
                title: this.$t(`services.${service.name}.items.${item.name}.title`),
                price: this.$t(`services.${service.name}.items.${item.name}.price`),
              });
            });
            let selected = null;
            if (this.service && this.item && this.service === service.name) {
              selected = service.items.map((item) => item.name).indexOf(this.item);
              if (selected < 0) {
                selected = null;
              }
            }
            items.push({
              title: this.$t(`services.${service.name}.title`),
              name: service.name,
              selected,
              subItems,
            });
          }
        });
      }
      return items;
    },
  },
  watch: {
    '$route.query.service': {
      handler(search) {
        let index = -1;
        if (search) {
          index = services.map((service) => service.name).indexOf(search);
        }
        this.panel = index < 0 ? null : index;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style scoped>

</style>
