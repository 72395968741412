<template>
  <v-sheet>
    <app-banner
      :src="require(`@/assets/images/1280/contact.jpg`)"
      :srcset="`${require(`@/assets/images/640/contact.jpg`)} 640w,
              ${require(`@/assets/images/1280/contact.jpg`)} 1280w,
              ${require(`@/assets/images/1920/contact.jpg`)} 1920w`"
      :title="$t('contact.title')"
      :subtitle="$t('contact.subtitle')"
    >
    </app-banner>
    <app-text
      :title="$t('contact.text.title')"
      :text="$t('contact.text.text')"
      dense
    >
    </app-text>
    <app-divider></app-divider>
    <app-text
      :title="$t('contact.data.title')"
      dense
    >
      <template v-slot:after>
        <p class="mt-6">
          <v-icon left color="primary">mdi-account</v-icon>
          {{ $t('contact.data.name') }}
        </p>
        <p>
          <a
            :href="`mailto:${$t('contact.data.email')}`"
            class="no-text-decoration accent--text"
            target="_blank"
          >
            <v-icon left color="primary">mdi-email</v-icon>
            {{ $t('contact.data.email') }}
          </a>
        </p>
        <p>
          <a
            :href="`tel:${$t('contact.data.phone.link')}`"
            class="no-text-decoration accent--text"
            target="_blank"
          >
            <v-icon left color="primary">mdi-phone</v-icon>
            {{ $t('contact.data.phone.display') }}
          </a>
        </p>
      </template>
    </app-text>
  </v-sheet>
</template>

<script>
import AppBanner from '@/components/AppBanner.vue';
import AppText from '@/components/AppText.vue';
import AppDivider from '@/components/AppDivider.vue';

export default {
  name: 'Contact',
  components: { AppDivider, AppText, AppBanner },
  metaInfo() {
    return {
      title: this.$t('contact.title'),
    };
  },
};
</script>

<style scoped>

</style>
