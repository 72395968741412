<template>
    <v-sheet>
      <app-banner :src="src" :srcset="srcset" :title="title" :subtitle="subtitle"></app-banner>
      <template v-for="(item, index) in items">
        <service-item
          :item="item"
          :revert="index % 2 === 1"
          :key="item.title"
        >
        </service-item>
        <app-divider :key="`${item.title}-divider`" v-if="index < items.length - 1"></app-divider>
      </template>
    </v-sheet>
</template>

<script>
import services from '@/data/services';
import ServiceItem from '@/components/ServiceItem.vue';
import AppDivider from '@/components/AppDivider.vue';
import AppBanner from '@/components/AppBanner.vue';

export default {
  name: 'Service',
  components: {
    AppBanner,
    AppDivider,
    ServiceItem,
  },
  computed: {
    service() {
      return services.find((service) => service.name === this.serviceName);
    },
    src() {
      return require(`@/assets/images/1280/${this.service.image}`);
    },
    srcset() {
      return `${require(`@/assets/images/640/${this.service.image}`)} 640w,
              ${require(`@/assets/images/1280/${this.service.image}`)} 1280w,
              ${require(`@/assets/images/1920/${this.service.image}`)} 1920w`;
    },
    title() {
      return this.$t(`services.${this.service.name}.title`);
    },
    subtitle() {
      return this.$t(`services.${this.service.name}.subtitle`);
    },
    items() {
      const items = [];
      if (this.service.items) {
        this.service.items.forEach((item) => {
          items.push({
            name: item.name,
            service: this.service.name,
            title: this.$t(`services.${this.service.name}.items.${item.name}.title`),
            description: this.$t(`services.${this.service.name}.items.${item.name}.description`),
            image: require(`@/assets/images/services/${item.image}`),
          });
        });
      }
      return items;
    },
  },
  props: {
    serviceName: {
      type: String,
      required: true,
    },
  },
};

</script>

<style lang="scss" scoped>

</style>
