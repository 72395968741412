<template>
  <div tabindex="-1" class="v-dialog__content v-dialog__content--active">
    <transition name="bottom-sheet-transition">
      <div class="v-dialog v-bottom-sheet v-dialog--active v-dialog--persistent"
           v-if="value">
        <v-bottom-sheet v-if:="false"></v-bottom-sheet>
        <app-container tile content-classes="py-4 py-md-12 px-6">
          <v-row align="center" justify="center">
            <v-col cols="12" md="8">
              <p class="primary--text" v-text="$t('cookies.consent.text')"></p>
              <router-link
                class="accent--text no-text-decoration caption"
                v-text="$t('cookies.consent.link')"
                :to="{name: 'cookies'}"
              >
              </router-link>
              <v-switch
                v-model="analytics"
                :label="$t('cookies.consent.analytics')"
                color="primary"
                hide-details
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4" class="d-flex align-center justify-end justify-md-center pa-1">
              <v-btn
                color="primary"
                class="white--text"
                large
                @click="$emit('consent', analytics)"
                v-text="$t('cookies.consent.accept')"
              >
              </v-btn>
            </v-col>
          </v-row>
        </app-container>
      </div>
    </transition>
  </div>
</template>

<script>
import AppContainer from '@/components/AppContainer.vue';

export default {
  name: 'CookieConsent',
  components: { AppContainer },
  data: () => ({
    analytics: false,
  }),
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
