<template>
    <v-sheet>
      <app-banner
        :src="require(`@/assets/images/1280/me.jpg`)"
        :srcset="`${require(`@/assets/images/640/me.jpg`)} 640w,
                  ${require(`@/assets/images/1280/me.jpg`)} 1280w,
                  ${require(`@/assets/images/1920/me.jpg`)} 1920w`"
        :title="$t('publicwriter.me.title')"
        :subtitle="$t('publicwriter.me.subtitle')"
      >
      </app-banner>
      <app-container tile>
        <v-row justify="center">
          <v-col cols="12">
            <h2 class="mb-2 app-title" v-text="$t('publicwriter.me.formation.title')"></h2>
            <p class="text-justify" v-text="$t('publicwriter.me.formation.text1')"></p>
            <p class="text-justify" v-text="$t('publicwriter.me.formation.text2')"></p>
          </v-col>
        </v-row>
      </app-container>
      <app-divider></app-divider>
      <app-container tile>
        <v-row justify="center">
          <v-col cols="12" md="6">
            <h2 class="mb-2 app-title" v-text="$t('publicwriter.me.history.title')"></h2>
            <p class="text-justify" v-text="$t('publicwriter.me.history.text')"></p>
          </v-col>
          <app-divider class="hidden-md-and-up my-6"></app-divider>
          <v-col cols="12" md="6">
            <h2 class="mb-2 app-title" v-text="$t('publicwriter.me.education.title')"></h2>
            <p class="text-justify" v-text="$t('publicwriter.me.education.text')"></p>
          </v-col>
        </v-row>
      </app-container>
      <app-divider></app-divider>
      <app-container tile>
        <v-row justify="center">
          <v-col cols="12">
            <h2 class="mb-2 app-title" v-text="$t('publicwriter.me.cnv.title')"></h2>
            <p class="text-justify" v-text="$t('publicwriter.me.cnv.text')"></p>
          </v-col>
        </v-row>
      </app-container>
      <app-divider></app-divider>
      <app-container tile>
        <v-row justify="center">
          <v-col cols="12">
            <h2 class="mb-2 app-title" v-text="$t('publicwriter.me.sport.title')"></h2>
            <p class="text-justify" v-text="$t('publicwriter.me.sport.text')"></p>
          </v-col>
        </v-row>
      </app-container>
      <app-divider></app-divider>
      <certifications></certifications>
    </v-sheet>
</template>

<script>
import AppContainer from '@/components/AppContainer.vue';
import AppBanner from '@/components/AppBanner.vue';
import AppDivider from '@/components/AppDivider.vue';
import Certifications from '@/components/Certifications.vue';

export default {
  name: 'Me',
  components: {
    Certifications,
    AppDivider,
    AppBanner,
    AppContainer,
  },
  metaInfo() {
    return {
      title: this.$t('publicwriter.me.title'),
    };
  },
};
</script>

<style scoped>

</style>
