<template>
  <v-app>
      <app-menu
        v-model="drawer"
        :appName="$t('app.name')"
        @navIconClick="drawer = !drawer"
        drawer
      ></app-menu>
      <v-content>
        <v-responsive class="mx-auto">
          <v-container fluid class="pa-0">
            <router-view></router-view>
          </v-container>
        </v-responsive>
      </v-content>
    <app-footer></app-footer>
    <cookie-consent v-model="cookies" @consent="cookieConsent"></cookie-consent>
  </v-app>
</template>

<script>

import AppMenu from '@/components/AppMenu.vue';
import AppFooter from '@/components/AppFooter.vue';
import CookieConsent from '@/components/CookieConsent.vue';
import { bootstrap } from 'vue-gtag';
import analyticsData from '@/data/analytics';

const COOKIE_CONSENT_NAME = 'CookieConsent';

export default {
  name: 'App',
  metaInfo() {
    return {
      title: this.$t('app.titles.default'),
      titleTemplate: this.$t('app.titles.template'),
      meta: [
        { name: 'description', content: this.$t('app.meta.description') },
        { name: 'keywords', content: this.$t('app.meta.keywords') },
        { property: 'og:title', content: this.$t('app.name') },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: this.$t('app.meta.og:url') },
        { property: 'og:image', content: require('@/assets/images/og_image.jpg') },
        { property: 'og:description', content: this.$t('app.meta.description') },
      ],
      link: [
        { rel: 'canonical', href: this.canonicalUrl() },
      ],
    };
  },
  components: {
    CookieConsent,
    AppFooter,
    AppMenu,
  },
  data: () => ({
    drawer: null,
    cookies: false,
  }),
  methods: {
    cookieConsent(consent) {
      this.cookies = false;
      this.initGtag(consent, () => {
        if (consent) {
          this.$gtag.event('Consent', {
            event_category: 'Cookies',
            event_label: 'Consented to cookies',
            value: true,
          });
        }
        this.$cookies.set(COOKIE_CONSENT_NAME, consent, 30 * 24 * 60 * 60);
      });
    },
    initGtag(enable, callback) {
      if (enable) {
        bootstrap().then(() => {
          this.$gtag.pageview({
            page_title: this.$route.name,
            page_path: this.$route.path,
            page_location: window.location.href,
          });
          if (callback) {
            callback();
          }
        });
      } else {
        window[`ga-disable-${analyticsData.id}`] = true;
        if (callback) {
          callback();
        }
      }
    },
    canonicalUrl() {
      let canonical = window.location.origin;
      if (window.prerender) {
        canonical = window.prerender.base;
      }
      if (this.$route.matched) {
        canonical += this.$route.matched[0].path;
      }
      return canonical;
    },
  },
  mounted() {
    if (window.prerender === undefined) {
      const cookieConsent = this.$cookies.get(COOKIE_CONSENT_NAME);
      if (cookieConsent != null) {
        this.initGtag(cookieConsent === 'true');
      }
      this.cookies = cookieConsent == null;
    }
  },
};
</script>

<style lang="scss">
  * {
    word-break: break-word !important;
  }

  .app-title {
    border-left: 3px solid var(--v-primary-base);
    padding-left: 12px;
  }

  .no-text-decoration {
    text-decoration: none !important;
  }

  .valign-middle {
    vertical-align: middle !important;
  }
</style>
