import Vue from 'vue';
import VueRouter from 'vue-router';
import services from '@/data/services';
import Home from '@/views/Home.vue';
import ServicesLayout from '@/views/services/ServicesLayout.vue';
import Services from '@/views/services/Services.vue';
import Pricing from '@/views/Pricing.vue';
import NotFound from '@/views/NotFound.vue';
import Contact from '@/views/Contact.vue';
import PublicWriterLayout from '@/views/publicwriter/PublicWriterLayout.vue';
import Job from '@/views/publicwriter/Job.vue';
import Me from '@/views/publicwriter/Me.vue';
import Cookies from '@/views/Cookies.vue';
import Testimony from '@/views/Testimony.vue';
import Disclaimer from '@/views/Disclaimer.vue';

Vue.use(VueRouter);

const servicesRoutes = [{
  path: 'liste',
  name: 'list',
  alias: '/prestations',
  component: Services,
  show: true,
  mobile: false,
}];

services.forEach((service) => {
  servicesRoutes.push({
    path: service.path,
    name: service.name,
    component: service.component,
    show: true,
    mobile: false,
  });
});

export const routes = [
  {
    path: '/accueil',
    alias: '/',
    name: 'home',
    icon: 'mdi-home',
    component: Home,
    show: true,
    mobile: false,
  },
  {
    path: '/ecrivainpublic',
    name: 'publicwriter',
    icon: 'mdi-book-open-page-variant',
    component: PublicWriterLayout,
    show: true,
    mobile: false,
    children: [
      {
        path: 'metier',
        name: 'job',
        alias: '/ecrivainpublic',
        component: Job,
        show: true,
        mobile: false,
      },
      {
        path: 'quisuisje',
        name: 'me',
        component: Me,
        show: true,
        mobile: false,
      },
    ],
  },
  {
    path: '/prestations',
    name: 'services',
    icon: 'mdi-pencil',
    component: ServicesLayout,
    show: true,
    mobile: false,
    children: servicesRoutes,
  },
  {
    path: '/temoignages',
    name: 'testimony',
    icon: 'mdi-comment-account',
    component: Testimony,
    show: true,
    mobile: false,
  },
  {
    path: '/tarifs',
    name: 'pricing',
    icon: 'mdi-currency-eur',
    component: Pricing,
    show: true,
    mobile: false,
  },
  {
    path: '/contact',
    name: 'contact',
    icon: 'mdi-email',
    component: Contact,
    show: true,
    mobile: true,
  },
  {
    path: '/mentionslegales',
    name: 'disclaimer',
    component: Disclaimer,
    show: false,
    mobile: false,
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: Cookies,
    show: false,
    mobile: false,
  },
  {
    path: '*',
    component: NotFound,
    show: false,
    mobile: false,
  },
];

const router = new VueRouter({
  mode: process.env.NODE_ENV === 'development' ? 'hash' : 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
