import Vue from 'vue';
import VueGtag from 'vue-gtag';
import router from '@/router';
import analyticsData from '@/data/analytics';

Vue.use(VueGtag, {
  config: {
    id: analyticsData.id,
    params: {
      cookie_expires: 30 * 24 * 60 * 60,
      send_page_view: false,
      allow_google_signals: false,
      allow_ad_personalization_signals: false,
      anonymize_ip: true,
    },
  },
  bootstrap: false,
}, router);
