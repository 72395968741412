<template>
  <app-container :color="color" tile>
    <v-row
      :class="`flex-md-row${revert ? '-reverse' : ''} flex-sm-column`"
      align="center"
      justify="center"
    >
      <v-col cols="12" md="5" :offset-md="revert ? 1 : 0">
        <h2 v-text="item.title" class="headline mb-2 app-title"></h2>
        <p
          v-for="paragraph in item.description.split('\n')"
          :key="paragraph"
          class="text-justify"
          v-text="paragraph"
        >
        </p>
        <slot name="actions">
          <v-btn
            :to="{name: 'contact'}"
            color="primary"
            class="mr-2 mb-2"
          >
            <v-icon left>mdi-email</v-icon>
            {{ $t('services.links.contact') }}
          </v-btn>
          <v-btn
            :to="{name: 'pricing', query: { service: item.service, item: item.name }}"
            color="primary"
            class="mb-2"
          >
            <v-icon left>mdi-currency-eur</v-icon>
            {{ $t('services.links.pricing') }}
          </v-btn>
        </slot>
      </v-col>
      <v-col cols="12" md="6" :offset-md="revert ? 0 : 1" class="hidden-sm-and-down">
        <v-img
          :src="item.image"
          max-height="300"
          class="elevation-6 rounded"
          :width="imageWidth"
          :height="imageHeight"
        >
        </v-img>
      </v-col>
    </v-row>
  </app-container>
</template>

<script>
import AppContainer from '@/components/AppContainer.vue';

export default {
  name: 'ServiceItem',
  components: { AppContainer },
  data: () => ({
    dialog: false,
  }),
  props: {
    item: {
      type: Object,
      required: true,
    },
    revert: {
      type: Boolean,
      required: false,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: null,
    },
    imageHeight: {
      type: Number,
      required: false,
      default: null,
    },
    imageWidth: {
      type: Number,
      required: false,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
  .rounded {
    border-radius: 8px;
  }
</style>
