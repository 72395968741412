<template>
  <v-sheet>
    <app-banner
      :src="require(`@/assets/images/1280/services.jpg`)"
      :srcset="`${require(`@/assets/images/640/services.jpg`)} 640w,
              ${require(`@/assets/images/1280/services.jpg`)} 1280w,
              ${require(`@/assets/images/1920/services.jpg`)} 1920w`"
      :title="$t('services.title')"
      :subtitle="$t('services.subtitle')"
    >
    </app-banner>
    <template v-for="(item, index) in items">
      <service-item
        :item="item"
        :revert="index % 2 === 1"
        :key="item.title"
        :image-height="300"
      >
        <template v-slot:actions>
          <v-btn
            :to="{name: item.name}"
            color="primary"
            class="mb-2"
          >
            <v-icon left>mdi-pencil</v-icon>
            {{ $t('services.links.service') }}
          </v-btn>
        </template>
      </service-item>
      <app-divider :key="`${item.title}-divider`" v-if="index < items.length - 1"></app-divider>
    </template>
  </v-sheet>
</template>

<script>
import ServiceItem from '@/components/ServiceItem.vue';
import AppBanner from '@/components/AppBanner.vue';
import AppDivider from '@/components/AppDivider.vue';
import services from '@/data/services';

export default {
  name: 'Services',
  components: { ServiceItem, AppBanner, AppDivider },
  metaInfo() {
    return {
      title: this.$t('services.list.title'),
    };
  },
  computed: {
    items() {
      const items = [];
      if (services) {
        services.forEach((service) => {
          items.push({
            name: service.name,
            service: service.name,
            title: this.$t(`services.${service.name}.title`),
            description: this.$t(`services.${service.name}.subtitle`),
            image: require(`@/assets/images/1280/${service.image}`),
          });
        });
      }
      return items;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
