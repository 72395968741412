import Rewrite from '@/views/services/Rewrite.vue';
import Redaction from '@/views/services/Redaction.vue';
import Pro from '@/views/services/Pro.vue';
import Correction from '@/views/services/Correction.vue';
import Workshop from '@/views/services/Workshop.vue';
import Associations from '@/views/services/Associations.vue';

const services = [
  {
    name: 'redaction',
    path: 'redaction',
    component: Redaction,
    image: 'redaction.jpg',
    items: [
      { name: 'correspondence', image: 'letter.jpg' },
      { name: 'hiring', image: 'job.jpg' },
      { name: 'speech', image: 'speech.jpg' },
      { name: 'story', image: 'story.jpg' },
      { name: 'web', image: 'web.jpg' },
      { name: 'literature', image: 'books.jpg' },
    ],
  },
  {
    name: 'rewrite',
    path: 'reecriture',
    component: Rewrite,
    image: 'rewrite.jpg',
    items: [
      { name: 'correspondence', image: 'letter.jpg' },
      { name: 'hiring', image: 'job.jpg' },
      { name: 'speech', image: 'speech.jpg' },
      { name: 'story', image: 'story.jpg' },
      { name: 'web', image: 'web.jpg' },
      { name: 'literature', image: 'books.jpg' },
      { name: 'scholar', image: 'school.jpg' },
    ],
  },
  {
    name: 'correction',
    path: 'correction',
    component: Correction,
    image: 'correction.jpg',
    items: [
      { name: 'correspondence', image: 'letter.jpg' },
      { name: 'hiring', image: 'job.jpg' },
      { name: 'speech', image: 'speech.jpg' },
      { name: 'story', image: 'story.jpg' },
      { name: 'web', image: 'web.jpg' },
      { name: 'literature', image: 'books.jpg' },
      { name: 'scholar', image: 'school.jpg' },
    ],
  },
  {
    name: 'pro',
    path: 'entreprises',
    component: Pro,
    image: 'pro.jpg',
    items: [
      { name: 'letters', image: 'letter_pro.jpg' },
      { name: 'speech', image: 'speech.jpg' },
      { name: 'story', image: 'story_pro.jpg' },
      { name: 'meeting', image: 'meeting.jpg' },
      { name: 'web', image: 'web.jpg' },
    ],
  },
  {
    name: 'workshop',
    path: 'ateliers',
    component: Workshop,
    image: 'workshop.jpg',
    items: [
      { name: 'workshop', image: 'workshop.jpg' },
    ],
  },
  {
    name: 'associations',
    path: 'associations',
    component: Associations,
    image: 'associations.jpg',
    items: [
      { name: 'associations', image: 'associations.jpg' },
    ],
  },
];

export default services;
